import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CardContent, CardHeader, Typography, Link as MuiLink } from '@mui/material';
import styled from 'styled-components';
import { Spacing } from 'Themes/spacing';
import { Typo } from 'Themes/typo';
import { coursesApi, JoinToCourseRequest } from 'Api';
import { urls } from 'Router';
import { useAppSelector } from 'Store';
import { LoginState, selectLoginState } from 'Auth/authSlice';
import { Link, Logo, linkStyle } from 'Components';
import { DateTimeInfo } from 'Common/DateTimeInfo';
import { StyledLogo, StyledButton } from 'Components/Styled';
import { StyledJoinCourseSkeleton } from './JoinToCoursePage.styles';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${Spacing.medium};
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${Spacing.medium};
`;

const LinksContainer = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: ${Spacing.xlarge};
  gap: ${Spacing.small};
`;

export const ButtonLink = styled(MuiLink)`
  ${linkStyle}
`;

export interface JoinToCoursePageProps {
  shouldSkipConfirmation: boolean;
}

export function JoinToCoursePage({ shouldSkipConfirmation }: JoinToCoursePageProps) {
  const { joinCode } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // const handleRedirectToMainPage = () => navigate('/');

  const loginState = useAppSelector(selectLoginState);

  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [courseTitle, setCourseTitle] = useState<string | null | undefined>(null);
  const [courseStartDate, setCourseStartDate] = useState<string | null | undefined>(null);
  const [courseErrorTranslationCode, setCourseErrorTranslationCode] = useState<
    string | null | undefined
  >(null);

  if (courseErrorTranslationCode === 'joined_already') {
    navigate(urls.homePage);
  }

  const handleYesClick = () => {
    coursesApi.coursesJoinToCoursePost({ joinCode } as JoinToCourseRequest).then(data => {
      setCourseErrorTranslationCode(data?.data?.errorTranslationCode);

      if (!data?.data?.errorTranslationCode) {
        navigate(urls.homePage);
      }
    });
  };

  const handleLoginClick = () => navigate(urls.loginPage, { state: { joinCode, courseTitle } });

  const handleRegisterClick = () =>
    navigate(urls.registrationPage, { state: { joinCode, courseTitle } });

  const checkStatusAndJoinToCourse = () =>
    coursesApi.coursesJoinStatusGet(joinCode as string).then(data => {
      setCourseTitle(data.data.title);
      setCourseStartDate(data.data.startDate);
      setCourseErrorTranslationCode(data.data.errorTranslationCode);

      if (!data.data.errorTranslationCode && shouldSkipConfirmation) {
        handleYesClick();
      }

      setIsDataLoaded(true);
    });

  useEffect(() => {
    checkStatusAndJoinToCourse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isDataLoaded ? (
    <>
      <StyledLogo>
        <Typography noWrap>{t('auth.welcomeIn')}</Typography>
        <Logo variant="login" />
      </StyledLogo>

      {courseErrorTranslationCode ? (
        <>
          {courseTitle && <CardHeader title={courseTitle}></CardHeader>}
          <CardHeader
            title={courseErrorTranslationCode && t(courseErrorTranslationCode)}
          ></CardHeader>

          <CardContent>
            {/* TODO: napewno usunąć? <Button onClick={handleRedirectToMainPage}>{t('BackMainPage')}</Button> */}
            <ButtonLink onClick={checkStatusAndJoinToCourse}>{t('auth.tryAgain')}</ButtonLink>
          </CardContent>
        </>
      ) : loginState !== LoginState.LOGGED ? (
        <Content>
          <div>{t('auth.toJoinTheTraining')}</div>
          <Typography variant={Typo.bigHeader}>{courseTitle}</Typography>
          <div>
            {t('auth.starting')}{' '}
            <span>
              <DateTimeInfo date={new Date(courseStartDate!)} />
            </span>
          </div>

          <ActionButtonsContainer>
            <StyledButton onClick={handleLoginClick}>{t('auth.login')}</StyledButton>

            <Typography noWrap>{t('auth.or')}</Typography>

            <StyledButton variant="outlined" onClick={handleRegisterClick}>
              {t('auth.signUp')}
            </StyledButton>
          </ActionButtonsContainer>

          <LinksContainer>
            <Link
              to={urls.forgotPasswordPage}
              label={t('auth.remindPassword')}
              shouldOpenInNewTab={false}
            />
            <Link to={urls.treneoSite} label={t('auth.whatIsTreneo')} />
          </LinksContainer>
        </Content>
      ) : (
        <Content>
          {/* TODO: ten warunek chyba jest nie ptrzebny skoro jeśli jest true to od razu w use effect przeniesie? */}
          {!shouldSkipConfirmation && (
            <>
              <div>{t('auth.course')}</div>
              <Typography variant={Typo.bigHeader}>{courseTitle}</Typography>
              <div>
                {t('auth.starts')}{' '}
                <span>
                  <DateTimeInfo date={new Date(courseStartDate!)} />
                </span>
              </div>

              <StyledButton onClick={handleYesClick}>{t('auth.join')}</StyledButton>
            </>
          )}
        </Content>
      )}
    </>
  ) : (
    <>
      <StyledJoinCourseSkeleton />
    </>
  );
}

export default JoinToCoursePage;
