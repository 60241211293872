import React from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes
} from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { captureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { persistor, store } from 'Store';
import { getAppConfig } from 'Config/configSlice';
import { isProductionEnvironment } from 'Utils/environments';
import App from './App';

import './i18n';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
store.dispatch(getAppConfig()).then((_result: any) => {
  const state = store.getState();

  if (isProductionEnvironment()) {
    const sentrySettings = state.config.sentrySettings;

    if (!sentrySettings?.dsn || !sentrySettings?.sentryEnvironment) {
      return;
    }

    Sentry.init({
      dsn: sentrySettings.dsn,
      environment: sentrySettings.sentryEnvironment,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        }),
        Sentry.replayIntegration({
          maskAllText: true,
          blockAllMedia: true
        }),
        captureConsoleIntegration()
      ],
      tracesSampleRate: 1.0
    });
  }

  const googleSettings = state.config.googleSettings;

  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        {googleSettings?.clientId ? (
          <GoogleOAuthProvider clientId={googleSettings.clientId}>
            <App />
          </GoogleOAuthProvider>
        ) : (
          <App />
        )}
      </PersistGate>
    </Provider>
  );
});
