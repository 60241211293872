import GoogleSimpleButton from './GoogleSimpleButton';

interface Props {
  readonly shouldShowGoogle: boolean;
}

export function ExternalLoginProviders({ shouldShowGoogle }: Props) {
  return shouldShowGoogle ? <GoogleSimpleButton /> : '';
}

export default ExternalLoginProviders;
